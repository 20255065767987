/*Appファイルで使用するスタイル*/
.App {
  text-align: center;
}
.app-container {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
 /*min-height: 100vh;*/
}

.app-header 
/*, .app-footer*/ {
  background-color: #ffffff;
  color: black;
  /*padding: 5px;*/
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  /*position: fixed;
  left: 0;*/

  top: 0; /* 上端に固定 */
  height: 60px; /* 固定高さ */
}

.app-header h1 {
  margin: 0;
  font-size: clamp(12px, 2.5vw, 20px); /* 最小値12px、推奨値2.5vw、最大値20px */
  font-weight: bold;
  /*
  position: absolute;
  left: 50%;
  height: 60px;
  transform: translateX(-50%);
*/
  }

.app-header p {
  margin: 0;
  font-size: clamp(10px, 2.5vw, 14px); /* 最小値10px、推奨値2.5vw、最大値14px */
  font-weight:none; 
}

.app-footer {
  background-color: #d3d5e4;
  color: black;
  padding: 10px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  max-width: 1000px;
  margin: 0 auto;
   /*position: fixed;*/
  left: 0;
}

.App-body {
  display: flex;
  flex-direction: row;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto; /* ビューポートの左右中央に配置 */
}

.App-menu {
  width: 280px; /* 横幅を指定 */
  background-color: #ffffff;
  padding: 10px;
  display: block; /* Always display the menu */
  /*z-index: 1000; /* 他の要素の上に表示 */
}

.App-link {
  color: #61dafb;
}

.content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 5px;
  background-color: #aabeb1;
  min-width: 320px; /* 最小横幅 */
  max-width: 800px; /* 最大横幅 */
  width: 100%;
  /*padding-top: 60px; /* ヘッダーの高さを考慮 */
  /*padding-bottom: 40px; /* フッターの高さを考慮 */
}

.menu-button {
  font-size: clamp(10px, 2.5vw, 14px); /* 最小値10px、推奨値2.5vw、最大値14px */
  cursor: pointer;
  padding: 0px 5px 0px 3px;
  margin: 0px 0px;
}

/* サインアウトボタンを右側に配置 */
.signout-button {
  font-size: clamp(10px, 2.5vw, 14px); /* 最小値10px、推奨値2.5vw、最大値16px */
  margin-left: auto; /* 左側のマージンを自動にして右側に配置 */
  padding: 3px;
  margin: 0px 0px;

}

/*画面が小サイズ時にメニューを非表示*/
@media (max-width: 768px) {
  .App-body {
    flex-direction: column;
  }
  
  .App-menu {
    position: fixed;
    top: 0px;
    left: 0;
    height: 100%;
    display: block;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
  }

  .App-body.menu-open .App-menu {
    transform: translateX(0);
  }

  .App-content {
    margin-top: 50px;
  }
}

@media (min-width: 769px) {
  .menu-button {
    display: none; /* Hide menu button on large screens */
  }
}
/* メニューバーのタイトルの属性を追加 */
/* リストの全体のスタイル設定 */
.App-menu ul  {
  cursor: pointer;
  list-style-type: none; /* 修正: listStyleType -> list-style-type */
  margin: 4px 0px 4px 0px;
  padding: 0px;
  transition: background-color 0.3s;
}

/* アイコンのスタイル設定 */
.App-menu i  {
  color: Dark Gray;
  padding: 0px 5px 0px 0px;
}

/* ホバー効果のスタイルを追加 */
.App-menu ul li {
  cursor: pointer;
  padding: 4px 0px 4px 15px;
  transition: background-color 0.3s;
}

.App-menu ul li:hover {
  background-color: #625d5d; /* ホバー時の背景色 */
}

/* 主コンテナのスタイル */
.App-main {
  width: 100%;
  {/* display: flex; //サイズ調整のため */}
  justify-content: center; /* 子コンテナを中央に配置 */
}

button {
  padding: 10px;
  margin: 10px 0px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #45a049;
}