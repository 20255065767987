/* DailyPlanのスタイル定義内容 */
.date-container {
  background-color: #ffffff;
  width: 100%;
  margin: 0px auto;
  padding: 5px;
  border-radius: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.date-container button,
.date-container i,
.date-container input,
.date-container label {
  font-size: clamp(12px, 2.5vw, 14px);
  font-weight: bold;
  align-items: center;
  padding: 0px 5px;
}

.date-container i {
  font-size: clamp(12px, 2.5vw, 20px);
  padding: 0px 10px;
}

.date-container input {
  padding: 0px 10px;
}

.date-container label {
  padding: 0px 5px 0px 0px;
}

/* 各コンテナのベース */
.display-container {
  background-color: #e3e8da;
  width: 100%;
  max-width: 600px;
  min-width: 300px;
  margin: 10px auto;
  padding: 0px;
  border-radius: 15px 15px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.display-container h1,
.display-container h2 {
  color: hsl(122, 85%, 8%);
  width: 100%;
  text-align: center;
  font-size: clamp(12px, 2.5vw, 20px);
  margin: 0%;
  padding: 0px;
}

.display-container h2 {
  text-align: left;
  font-size: clamp(12px, 2.5vw, 14px);
  border: 0px;
  font-weight: normal;
  text-indent: 20px;
}

.display-inner-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.display-container p {
  background-color: #ffffff;
  color: #1a1818;
  display: block;
  width: 98%;
  font-size: clamp(12px, 2.5vw, 14px);
  font-family: "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  text-align: left;
  min-height: 70px;
  max-height: 250px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.display-container hr {
  background-color: #ab1212;
  color: #000;
  width: 100%;
  height: 2px;
  border: none;
  margin: 10px 0;
}

.input-text {
  font-size: clamp(12px, 2.5vw, 14px);
  color: black;
  text-align: center;
  width: 85%;
  font-family: "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  margin: 10px auto;
  border-radius: 50px;
  padding: 5px;
  border: 1px solid #ccc;
  box-shadow: inset 3px 2px 4px 0px rgb(100 92 92);
  background-color: #f9f9f9;
}

.input-text:hover {
  background-color: rgb(179, 171, 165);
}

.score-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  margin-top: 10px;
  align-items: center;
}

.score-container input
 {
  flex-basis: 10%;
  margin: 0px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  align-items: center;
  min-height: 25px;
  margin: 0;
  padding: 0;
}

.score-container p {
  flex-basis:  25%;
  text-indent: 0%;
  min-height: unset;
  background-color: unset;
}

.score-container h2 {
  flex-basis: 65%;
  text-indent: 0%;
}

.base-container {
  background-color: silver;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.button-container button {
  width: 80%;
  padding: 5px;
  margin: 10px 0px;
}

button {
  padding: 10px;
  margin: 10px 0px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #45a049;
}

.guidance-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.guidance-image {
  flex: 0 0 auto;
  margin-right: 1rem;
}

.guidance-image img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}

.guidance-text {
  flex: 1 1 auto;
}

.speech-bubble {
  position: relative;
  background: #f0f0f0;
  border-radius: .4em;
  padding: 10px;
  margin-left: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: calc(12px + 0.5vmin);
  line-height: 1.4;
  max-height: 100px;
  overflow: visible;
}

.speech-bubble-content {
  max-height: 100px;
  overflow-y: auto;
  padding-right: 10px;
}

.speech-bubble:after {
  content: '';
  position: absolute;
  left: 0;
  top: 20%;
  width: 0;
  height: 0;
  border: 15px solid transparent;
  border-right-color: #f0f0f0;
  border-left: 0;
  margin-top: -15px;
  margin-left: -15px;
}

.speech-bubble p {
  margin: 0;
  padding: 0;
}

.reset-button {
  padding: 5px 10px;
  font-size: 16px;
}

.score-judgment {
  font-size: 100px;
  font-weight: bold;
  text-align: center;
}

.score-judgment p {
  margin: 0;
  font-size: 50px;
  font-weight: bold;
}

