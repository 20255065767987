.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    position: relative;
    background: white;
    padding: 0px 20px 20px 20px;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* ボタンを中央寄せ */

  }
  
  .modal-close-container {
    display: flex;
    width: 100%;
    justify-content: flex-end; /* ボタンを右寄せ */
  }
  
  .modal-close {
    background: silver;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #1a1818;
    padding: 0px 8px;
  }

  .modal-text {
    width: 100%;
    justify-content: center;
    font-family: "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif; /* 追加 */
 }

 .modal-button-container {
  display: flex;
  justify-content: center; /* ボタンを中央寄せ */

 }

 .modal-button {
  width: 100%;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: silver;
  color: black;
 }